import { useState, useEffect, useRef } from 'react';
import { 
	Flex,
	Image,
	Text,
  	ChakraProvider,
	Spinner,
	Button
} from "@chakra-ui/react";
import "./App.scss";
import "./mdi/css/materialdesignicons.css";
import Aos from "aos";
import "aos/dist/aos.css";
import Toast from "./toast"
import Logo from "./logo.png"
import img1 from "./img1.jpeg"
import img2 from "./img2.jpeg"



function App() {
	useEffect(() => {
		Aos.init({ duration: 800 })
	}, [])
	const [loading, setLoading] = useState(false)
	
	return (
		<ChakraProvider>
			<Flex w="100%" direction="column" fontSize="15px" color="#000">
				<Flex w="100%" position="sticky" top="0" justify="space-between" align="center" py="4" borderBottom="1px solid rgb(230,230,235)" zIndex="1000" px={["4%", "6%"]} bg="#fff" boxShadow="0 4px 2px -2px rgba(0, 0, 0, 0.08)">
					<Flex align="center" cursor="pointer" onClick={() => window.location.reload()}>
						<Image h={["40px", "50px"]} src={Logo} w="auto" />
						<Text ml="3">
							<Text color="red" fontWeight="700" fontSize={["18px", "20px"]}>Red</Text>
							<Text fontSize="13px" mt="-2">Carpet Rundown</Text>
						</Text>
					</Flex>

					<Flex>
						<Flex align="center" cursor="pointer" onClick={() => {
								const element = document.getElementById('comsec')
								return window.scrollTo({
								top: element ? element.offsetTop - 80 : 0,
								behavior: 'smooth',
								})
							}}>
							<Text mr={["0", "2"]} fontSize={["25px", "20px"]}><i className='mdi mdi-email'></i></Text>
							<Text textDecor="underline" fontSize={["13px", "15px"]} display={["none", "block"]}>Subscribe</Text>
						</Flex>
					</Flex>
				</Flex>

				<Flex w="100%" pt="60px" px={["5%", "20%"]} direction="column">
					<Text fontSize={["30px", "40px"]} lineHeight="1.15" fontWeight="bold" fontFamily="'Cardo', serif" data-aos="fade-down">A Closer Look at the Divorce Rumor: Stephen Amell and Cassandra Jean</Text>

					<Text mt="8" color="#111111">
						<Text>Last week Friday — by Author006</Text>
						<Text>Category: Celebrities</Text>
						
						<Flex borderTop="1px solid rgb(220,220,230)" my="8"></Flex>

						<Flex w="100%" h={["228px", "400px"]} data-aos="fade-up" backgroundImage={img1} borderRadius="5px" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="top center"></Flex>

						<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="24px" mt="8">Introduction</Text>
						<Text>In the world of celebrity gossip, divorce rumors often swirl around like wildfire. Recently, the news of actor Stephen Amell and his wife Cassandra Jean allegedly heading for a divorce has left their fans shocked and curious. The rumor suggests that the Arrow star has been unfaithful to his wife, with another woman in the picture. However, let us dive deeper into the situation before jumping to conclusions, as we explore the evidence, the couple's journey, and the potential outcome.</Text>

						<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="24px" mt="6">Researching the Evidence</Text>
						<Text>While tabloids and social media often fuel rumors, it is crucial to rely on verified sources for accurate information. In this case, a trusted source has come forward with supposed evidence supporting the rumor. However, it is important to remember that even trusted sources can sometimes misconstrue the facts. Before assuming guilt, let us take a step back and consider the complexities of personal relationships.</Text>

						<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="24px" mt="6">Understanding Stephen and Cassandra's Journey</Text>
						<Text>Stephen Amell, widely recognized for his role as Oliver Queen in the hit TV series Arrow, has been married to Cassandra Jean since 2012. The couple has always appeared strong and supportive of one another in public, making this alleged infidelity all the more startling.</Text>
						<Text mt="2">It is essential to remember that relationships are multifaceted, and no one can comprehend the true dynamics behind closed doors. Public perceptions can often be misleading, especially regarding celebrity relationships. The stress and pressure of fame can sometimes take a toll on even the strongest partnerships, leading to speculation and misunderstandings.</Text>

						<Flex mt="8" w="100%" data-aos="fade-up" h={["228px", "400px"]} backgroundImage={img2} borderRadius="5px" backgroundRepeat="no-repeat" backgroundSize="cover" backgroundPosition="top center"></Flex>

						<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="24px" mt="6">Navigating Public Scrutiny</Text>
						<Text>Being in the public eye brings constant scrutiny, and celebrities often find themselves at the center of unfounded rumors and speculations. This alleged divorce rumor serves as yet another example of how media attention can invade the privacy of individuals, placing incredible strain on relationships. It is vital to approach such rumors with empathy and respect for the people involved.</Text>

						<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="24px" mt="6">Potential Outcomes</Text>
						<Text>Speculating on the future of any relationship is challenging and should be done with caution. As outsiders, we can only speculate on the potential outcomes of Stephen Amell and Cassandra Jean's situation. They have not directly addressed the rumors, making it even more challenging to navigate the situation. It is crucial to respect their privacy during this delicate time and refrain from making uninformed assumptions.</Text>

						<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="24px" mt="6">Conclusion</Text>
						<Text>The divorce rumor between Stephen Amell and Cassandra Jean has undoubtedly stirred up curiosity and concern among their families. However, it is important to remember that celebrities, just like anyone else, deserve privacy and the opportunity to address personal matters on their terms. Instead of jumping to conclusions based on sensationalized rumors, let us remember that relationships are intricate and personal.</Text>
						<Text mt="6">As fans, we should respect Stephen and Cassandra's privacy and allow them the time and space to address the matter, should they choose to do so. Ultimately, it is their journey to navigate, and we can only hope for the best for both of them, regardless of the outcome.</Text>
					</Text>

					<Flex borderTop="1px solid rgb(220,220,230)" my="8" id="comsec"></Flex>
					
					<Text fontWeight="bold" fontFamily="'Cardo', serif" fontSize="34px">Comment</Text>
					<Text mt="8">Your email address will not be published. Required fields are marked *</Text>
					<form style={{ width: "100%" }} onSubmit={(e) => {
						e.preventDefault()
						setLoading(true)
						setTimeout(function() {
							Toast("Your comment has been recieved!!", "success")
							setLoading(false)
						}, 1800)
					}}>
						<Text mt="2" mb="2">Comment *</Text>
						<textarea style={{ width: "100%", resize: "none", height: "200px", border: "2px solid #111111", borderRadius: "8px", padding: "12px 20px"}} required></textarea>
						<Text mt="5" mb="2">Name  *</Text>
						<input style={{ width: "100%", border: "2px solid #111111", borderRadius: "8px", padding: "12px 20px"}} required />
						<Text mt="5" mb="2">Email  *</Text>
						<input style={{ width: "100%", border: "2px solid #111111", borderRadius: "8px", padding: "12px 20px"}} required />

						<Flex mt="5"><Button borderRadius="8px" type="submit" bg="#111111" cursor="pointer" h="60px" p="12px 40px" color="#fff">{loading ? <Spinner color="#fff" emptyColor="lightgrey" /> : "Post Comment"}</Button></Flex>
					</form>


					
					<Flex borderTop="1px solid rgb(220,220,230)" my="8"></Flex>

					<Text textAlign="center" pb="12">&copy; Red Carpet Rundown</Text>
				</Flex>
			</Flex>
		</ChakraProvider>
	)
}

export default App;
